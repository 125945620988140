import {createRouter,createWebHashHistory} from 'vue-router'
const routes = [
    {path: '/',name:"home",component: () => import('@/pages/LuckyWheel')},
    { path: '/rule',name:'rule' , component: () => import("@/pages/LuckyRule") },
    { path: '/search',name:'search' , component: () => import("@/pages/LuckySearchResult") },
]
const router = createRouter({
    history: createWebHashHistory(),
    routes: routes
})
router.beforeEach((to,from,next)=>{
    if (to.path == "/") {
        sessionStorage.setItem("token",to.query.token);
        next();
    } else  {
        next();
    }
    console.log(from)
    // next();
})
export default router
