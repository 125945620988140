import { createApp } from 'vue'
import App from './App.vue'
import Router from "@/router";
import VueLuckyCanvas from '@lucky-canvas/vue'
import {Dialog,List} from 'vant'
import 'vant/lib/index.css';
createApp(App)
    .use(Router)
    .use(VueLuckyCanvas)
    .use(Dialog)
    .use(List)
    .mount('#app')
